import 'reflect-metadata';
import * as Sentry from "@sentry/react";
import {HTTP_CODES} from "~common/constants";

const {
  BAD_REQUEST
} = HTTP_CODES

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["mp-key"],
        behaviour: "drop-error-if-contains-third-party-frames",
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [BAD_REQUEST, [500, 599]]
      })
    ],
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    ignoreErrors: ['ResizeObserver'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_IS_ONBOARDING) {
  import(
    /* webpackChunkName: "index-onboarding" */
    /* webpackMode: "lazy" */
    './indexOnboarding'
    );
} else {
  import(
    /* webpackChunkName: "index-app" */
    /* webpackMode: "lazy" */
    './indexApp'
    );
}
