export const HTTP_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  TOO_MANY_REQUESTS: 429,
  VALIDATION_ERROR: 422,
  SERVER_ERROR: 500,
}

export const RESPONSE_CODES = {
  FORBIDDEN_WITHOUT_2FA: 'FORBIDDEN_WITHOUT_2FA'
}

export const DEFAULT_TIMER_SECONDS = 59;

export const PAYMENT_BUTTONS = 'buttons';
export const PAYMENT_CARDS = 'cards';
export const PAYMENT_WALLETS = 'digital';
export const PAYMENT_MOBILE = 'mobile';
export const PAYMENT_CRYPTO = 'crypto';

export const ICONS_METHODS_NAME = 'methods';
export const ICONS_CATEGORY_NAME = 'categories';
export const ICONS_BUTTONS_NAME = 'buttons';
